import React from "react";
import Button from "./Button";
import { LuckGameState } from "./GameModel";

interface WinnerModalProps {
  G: LuckGameState;
  didIWin: boolean;
  winner: string;
  children?: React.ReactNode;
}

const WinnerModal: React.FC<WinnerModalProps> = ({ G, didIWin, winner }) => {
  return (
    <div className="absolute z-20 w-full px-2">
      <div className="bg-green-600 p-5 m-5 rounded-md flex flex-col gap-5 justify-between text-center">
        <div className="text-lg">
          {didIWin
            ? "You Won the Game!"
            : `You Lost the Game (Player ${Number(winner) + 1} won)`}
        </div>
        <div>
          {Object.entries(G.players).map(([_, player], index) => {
            return (
              <div key={index}>
                <span>
                  Player {index + 1}: {player.position}. Place
                </span>
              </div>
            );
          })}
        </div>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh Page
        </Button>
      </div>
    </div>
  );
};

export default WinnerModal;
