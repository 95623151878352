import React from "react";
import CardView from "./CardView";
import { LuckPlayerState } from "./GameModel";

interface OpponentHandProps {
  playerID: string;
  player: LuckPlayerState;
  faceUp?: boolean;
  highlight?: boolean;
}

const OpponentHand: React.FC<OpponentHandProps> = ({
  playerID,
  player,
  faceUp,
  highlight,
}) => {
  return (
    <div>
      <div className={`text-center ${highlight ? "underline" : ""}`}>
        Player {Number(playerID) + 1}
      </div>
      <div>
        <div
          className={`relative pt-2 px-2 rounded-md grid grid-cols-3 gap-1 mt-3 -mb-4 max-w-xl max-h-20 z-10`}
        >
          {player.visibleDeck.map((c) => (
            <div
              key={c.id}
              className="relative max-h-20 flex items-center justify-center"
            >
              <CardView card={c} />
            </div>
          ))}
        </div>
        <div
          className={`pb-2 px-2 rounded-md grid grid-cols-3 gap-1 mb-3 max-w-xl max-h-20`}
        >
          {player.hiddenDeck.map((c) => (
            <div
              key={c.id}
              className="relative max-h-20 flex items-center justify-center"
            >
              <CardView showBack />
            </div>
          ))}
        </div>
      </div>
      <div
        className={`p-2 rounded-md flex justify-evenly max-w-xl pr-6 max-h-20`}
      >
        {new Array(player.handLength).fill(0).map((n, i) => {
          return (
            <div
              className="relative flex flex-col items-center max-h-full -mr-4 rounded-sm"
              key={i}
            >
              {faceUp ? (
                <div>
                  <CardView card={player.hand[i]} />
                </div>
              ) : (
                <CardView showBack />
              )}
            </div>
          );
        })}
      </div>
      {player.position ? (
        <div className="text-center">{player.position}. Place</div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default OpponentHand;
