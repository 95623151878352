import React from "react";
import useCardImage from "./hooks/useImage";
import { LuckCard } from "./GameModel";

interface CardViewProps {
  card?: LuckCard;
  showBack?: boolean;
  none?: boolean;
}

const CardView: React.FC<CardViewProps> = ({ card, showBack, none }) => {
  const { loading, error, image } = useCardImage(
    `${showBack ? "card_back" : none ? "none" : card?.id ?? "none"}`
  );

  if (loading) {
    return <span>🃏</span>;
  }
  if (error) {
    return <span>Error!</span>;
  }
  return (
    <img
      className="object-contain h-full"
      src={image}
      alt={showBack ? "face down card" : card?.id ?? "no card"}
    />
  );
};

export default CardView;
