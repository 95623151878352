import { Ctx, Game, Move } from "boardgame.io";
import { INVALID_MOVE, TurnOrder } from "boardgame.io/core";
import {
  CardSuit,
  LuckCard,
  LuckCtx,
  LuckGameState,
  LuckPhase,
  LuckStage,
  PlayerMap,
} from "./GameModel";

const drawCardFromDrawPile: Move<LuckGameState> = (G, ctx, end = true) => {
  const theCard = G.drawPile.pop();
  if (!theCard) {
    return INVALID_MOVE;
  }

  G.discardPileLen = G.discardPile.length;
  G.drawPileLen = G.drawPile.length;
  const p = G.players[ctx.currentPlayer];
  p.hand.push(theCard);
  p.handLength = p.hand.length;
  G.players[ctx.currentPlayer].hand.sort(cardCompareFn);
  if (end) ctx.events?.endStage();
};

const pickUpDiscardPile: Move<LuckGameState> = (G, ctx, end = true) => {
  if (G.discardPile.length === 0) {
    return INVALID_MOVE;
  }

  while (G.discardPileLen !== 0) {
    const theCard = G.discardPile.pop();

    if (!theCard) {
      return INVALID_MOVE;
    }

    const p = G.players[ctx.currentPlayer];
    p.hand.push(theCard);
    p.handLength = p.hand.length;
    G.discardPileLen = G.discardPile.length;
  }

  G.players[ctx.currentPlayer].hand.sort(cardCompareFn);
  
  if (end) ctx.events?.endTurn();
};

const deleteDiscardPile: Move<LuckGameState> = (G, ctx) => {
  G.discardPile = [];
  G.discardPileLen = 0;
};

const drawToFull: Move<LuckGameState> = (G: LuckGameState, ctx: Ctx) => {
  let p = G.players[ctx.currentPlayer];
  let hand = p.hand;

  while (hand.length < 3) {
    if (G.drawPile.length === 0) {
      return;
    }

    drawCardFromDrawPile(G, ctx, false);
    p = G.players[ctx.currentPlayer];
    hand = p.hand;
  }
};

const discardCard: Move<LuckGameState> = (G, ctx, cards: LuckCard[]) => {
  let fullSet = cards.length === 4;
  for (let i = 0; i < cards.length; i++) {
    const theCard = cards[i];

    if (G.discardPile.length !== 0) {
      const previous = G.discardPile[G.discardPile.length - 1].ordinal;
      if (!checkValidity(previous, theCard.ordinal)) return INVALID_MOVE;
    }

    const p = G.players[ctx.currentPlayer];
    const hand = p.hand;
    const idx = hand.findIndex((c) => c.id === theCard.id);
    if (idx < 0) {
      return INVALID_MOVE;
    }
    const discardedCard = hand.splice(idx, 1)[0];
    p.handLength = hand.length;
    G.discardPile.push(discardedCard);
    G.discardPileLen = G.discardPile.length;
    if (theCard.ordinal === 10) {
      deleteDiscardPile(G, ctx);
      drawToFull(G, ctx);
      if (
        !(
          p.hand.length === 0 &&
          p.visibleDeck.length === 0 &&
          p.hiddenDeck.length === 0
        )
      )
        return;
    }
  }
  if (fullSet) {
    deleteDiscardPile(G, ctx);
    drawToFull(G, ctx);
    return;
  }
  drawToFull(G, ctx);
  ctx.events?.endTurn();
};

const pickUpCard: Move<LuckGameState> = (G, ctx, cards: LuckCard[]) => {
  for (let i = 0; i < cards.length; i++) {
    const theCard = cards[i];
    const p = G.players[ctx.currentPlayer];
    const visibleDeck = p.visibleDeck;
    if (visibleDeck.length <= 0) {
      return INVALID_MOVE;
    }
    const idx = visibleDeck.findIndex((c) => c.id === theCard.id);
    if (idx < 0) {
      return INVALID_MOVE;
    }
    const discardedCard = visibleDeck.splice(idx, 1)[0];
    p.visibleDeckLength = p.visibleDeck.length;

    p.hand.push(discardedCard);
    p.handLength = p.hand.length;
    G.players[ctx.currentPlayer].hand.sort(cardCompareFn);
  }
  return;
};

const placeCard: Move<LuckGameState> = (G, ctx, cards: LuckCard[]) => {
  for (let i = 0; i < cards.length; i++) {
    const theCard = cards[i];
    const p = G.players[ctx.currentPlayer];
    const hand = p.hand;
    const visibleDeck = p.visibleDeck;
    if (visibleDeck.length >= 3) {
      return INVALID_MOVE;
    }
    const idx = hand.findIndex((c) => c.id === theCard.id);
    if (idx < 0) {
      return INVALID_MOVE;
    }
    const discardedCard = hand.splice(idx, 1)[0];
    p.handLength = hand.length;
    visibleDeck.push(discardedCard);
    p.visibleDeckLength = visibleDeck.length;
  }
  return;
};

const swapDone: Move<LuckGameState> = (G, ctx) => {
  const p = G.players[ctx.currentPlayer];
  const hand = p.hand;
  const visibleDeck = p.visibleDeck;
  if (visibleDeck.length !== 3) {
    return INVALID_MOVE;
  }
  hand.sort(cardCompareFn);
  ctx.events?.endTurn();
};

const discardVisibleCard: Move<LuckGameState> = (G, ctx, cards: LuckCard[]) => {
  for (let i = 0; i < cards.length; i++) {
    const theCard = cards[i];
    if (G.discardPile.length !== 0) {
      const previous = G.discardPile[G.discardPile.length - 1].ordinal;
      if (!checkValidity(previous, theCard.ordinal)) return INVALID_MOVE;
    }

    const p = G.players[ctx.currentPlayer];
    const hand = p.visibleDeck;
    const idx = hand.findIndex((c) => c.id === theCard.id);
    if (idx < 0) {
      return INVALID_MOVE;
    }
    const discardedCard = hand.splice(idx, 1)[0];
    p.visibleDeckLength = hand.length;
    G.discardPile.push(discardedCard);
    G.discardPileLen = G.discardPile.length;
    if (theCard.ordinal === 10) {
      deleteDiscardPile(G, ctx);
      drawToFull(G, ctx);
      return;
    }
  }
  drawToFull(G, ctx);
  ctx.events?.endTurn();
};

const discardHiddenCard: Move<LuckGameState> = (G, ctx, cards: LuckCard[]) => {
  if (cards.length !== 1) return INVALID_MOVE;
  const theCard = cards[0];
  let canDiscard = true;
  if (G.discardPile.length !== 0) {
    const previous = G.discardPile[G.discardPile.length - 1].ordinal;
    if (!checkValidity(previous, theCard.ordinal)) {
      canDiscard = false;
    }
  }

  const p = G.players[ctx.currentPlayer];
  const hidden = p.hiddenDeck;
  const idx = hidden.findIndex((c) => c.id === theCard.id);
  if (idx < 0) {
    return INVALID_MOVE;
  }
  const discardedCard = hidden.splice(idx, 1)[0];
  p.hiddenDeckLength = hidden.length;
  if (!canDiscard) {    
    pickUpDiscardPile(G, ctx, false)
  }
  G.discardPile.push(discardedCard);
  G.discardPileLen = G.discardPile.length;
  if (theCard.ordinal === 10) {
    deleteDiscardPile(G, ctx);
    if (
      !(
        p.hand.length === 0 &&
        p.visibleDeck.length === 0 &&
        p.hiddenDeck.length === 0
      )
    )
      return;
  }

  ctx.events?.endTurn();
};

function checkValidity(previous: number, current: number): boolean {
  const special = [7, 9, 10];
  const picture = [11, 12, 13];

  if (previous === current) {
    return true;
  }

  if (!special.includes(previous) && previous < current) {
    return true;
  } else if (!special.includes(previous) && previous > current) {
    if (current === 2) {
      return true;
    } else if (current === 10) {
      return true;
    }
    return false;
  }
  switch (previous) {
    case 7:
      if (previous > current) {
        return true;
      } else {
        return false;
      }

    case 9:
      if (picture.includes(current)) {
        return true;
      } else {
        return false;
      }

    default:
      return false;
  }
}

export const Luck: Game<LuckGameState, LuckCtx> = {
  name: "Luck",
  maxPlayers: 4,
  setup: (ctx) => {
    const deck = makeDeck();

    const drawPile = ctx.random!.Shuffle(deck);
    const playerMap = makePlayers(ctx);
    for (let player of Object.values(playerMap)) {
      player.hand.push(...drawPile.splice(0, 3));
      player.hand.sort(cardCompareFn);
      player.handLength = player.hand.length;
      player.visibleDeck.push(...drawPile.splice(0, 3));
      player.visibleDeckLength = player.visibleDeck.length;
      player.hiddenDeck.push(...drawPile.splice(0, 3));
      player.hiddenDeckLength = player.hiddenDeck.length;
    }
    const discardPile = [];
    return {
      drawPile,
      drawPileLen: drawPile.length,
      discardPile,
      discardPileLen: discardPile.length,
      players: playerMap,
      playOrder: ctx.playOrder,
      playOrderPos: ctx.playOrderPos,
      currentPlayer: ctx.currentPlayer,
    };
  },
  endIf: (G) => {
    // win if you are the last player standing
    if (G.playOrder.length === 1) {
      for (const [pId, p] of Object.entries(G.players)) {
        if (p.position === 1) return { winner: pId };
      }
    }
  },
  phases: {
    [LuckPhase.Swap]: {
      start: true,
      turn: {
        order: TurnOrder.ONCE,
        activePlayers: {
          currentPlayer: LuckStage.Swapping,
        },
        stages: {
          [LuckStage.Swapping]: {
            moves: {
              pickUpCard,
              placeCard,
              swapDone,
            },
          },
        },
      },
      next: LuckPhase.Play,
    },
    [LuckPhase.Play]: {
      turn: {
        order: {
          first: (G, ctx) => {
            return ctx.playOrder.indexOf(G.playOrder[G.playOrderPos]);
          },
          next: (G, ctx) => {
            return ctx.playOrder.indexOf(G.playOrder[G.playOrderPos]);
          },
        },
        onEnd: (G) => {
          for (const [pId, p] of Object.entries(G.players)) {
            if (!G.playOrder.includes(pId)) {
              continue;
            }

            if (
              p.hand.length === 0 &&
              p.visibleDeck.length === 0 &&
              p.hiddenDeck.length === 0
            ) {
              p.position =
                Object.entries(G.players).length - G.playOrder.length + 1;
              const idx = G.playOrder.indexOf(pId);
              G.playOrder.splice(idx, 1);
              G.playOrderPos = G.playOrderPos - 1;
            }
          }

          if (G.playOrder.length === 1) {
            const p = G.players[G.playOrder[0]];
            p.position = Object.entries(G.players).length;
          }

          G.playOrderPos = (G.playOrderPos + 1) % G.playOrder.length;
        },
        activePlayers: {
          currentPlayer: LuckStage.Play,
        },
        stages: {
          [LuckStage.Play]: {
            moves: {
              discardCard,
              drawCardFromDrawPile,
              pickUpDiscardPile,
              discardVisibleCard,
              discardHiddenCard,
            },
            next: LuckStage.PlayPickup,
          },
          [LuckStage.PlayPickup]: {
            moves: {
              discardCard,
              pickUpDiscardPile,
            },
          },
        },
      },
    },
  },
  playerView: (G, ctx, playerID) => {
    // TODO if player is eliminated, show them the entire game state (basically, allow them to spectate)
    if (!playerID || !G.playOrder.includes(playerID)) {
      return G;
    }

    const GG = { ...G };

    GG.drawPile = [];
    GG.discardPile = GG.discardPile.slice(GG.discardPile.length - 1);
    GG.players = JSON.parse(JSON.stringify(G.players));

    for (const [pID, p] of Object.entries(GG.players)) {
      if (pID !== playerID) {
        p.handLength = p.hand.length;
        p.hand = [];
      }
    }
    return GG;
  },
};

export function makeDeck(): LuckCard[] {
  const symbols = [
    "A",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "J",
    "Q",
    "K",
  ];
  const suits = Object.values(CardSuit);
  const cards: LuckCard[] = [];
  for (const suit of suits) {
    cards.push({
      id: `${suit}_${symbols[0]}`,
      ordinal: 14,
      suit: suit,
      symbol: symbols[0],
    });
  }
  for (let i = 1; i < symbols.length; i++) {
    for (const suit of suits) {
      cards.push({
        id: `${suit}_${symbols[i]}`,
        ordinal: i + 1,
        suit: suit,
        symbol: symbols[i],
      });
    }
  }
  return cards;
}

function makePlayers(ctx: Ctx): PlayerMap {
  let players: PlayerMap = {};
  for (let p of ctx.playOrder) {
    players[p] = {
      hand: [],
      handLength: 0,
      position: 0,
      visibleDeck: [],
      visibleDeckLength: 0,
      hiddenDeck: [],
      hiddenDeckLength: 0,
    };
  }
  return players;
}

export function cardCompareFn(a: LuckCard, b: LuckCard): number {
  return a.ordinal - b.ordinal;
}
