import type { Ctx, PlayerID } from "boardgame.io";

export enum LuckStage {
  PlayPickup = "playpickup",
  Play = "play",
  Swapping = "swapping",
}

export enum LuckPhase {
  Swap = "swap",
  Play = "play",
}

export enum CardSuit {
  Hearts = "Hearts",
  Diamonds = "Diamonds",
  Clubs = "Clubs",
  Spaded = "Spades",
}

export interface LuckCard {
  id: string;
  ordinal: number;
  suit: CardSuit;
  symbol: string;
}

export interface LuckPlayerState {
  hand: LuckCard[];
  handLength: number;
  position: number;
  visibleDeck: LuckCard[];
  visibleDeckLength: number;
  hiddenDeck: LuckCard[];
  hiddenDeckLength: number;
}

export interface PlayerMap {
  [playerID: string]: LuckPlayerState;
}

export interface LuckGameState {
  drawPile: LuckCard[];
  drawPileLen: number;
  discardPile: LuckCard[];
  discardPileLen: number;
  players: PlayerMap;
  playOrder: Array<PlayerID>;
  playOrderPos: number;
}

export interface GameEndState {
  winner: string;
}

export interface LuckCtx extends Ctx {}
