import React, { useRef, useState } from "react";
import { BoardProps } from "boardgame.io/react";

import {
  GameEndState,
  LuckCard,
  LuckGameState,
  LuckPlayerState,
  LuckStage,
} from "./GameModel";
import CardView from "./CardView";
import Button from "./Button";
import OpponentHand from "./OpponentHand";
import EndGameInfo from "./EndOfGame";

interface LuckBoardProps extends BoardProps<LuckGameState> {}

const LuckBoard: React.FC<LuckBoardProps> = ({ G, ctx, moves, playerID }) => {
  playerID = playerID!;
  const activePlayers = ctx.activePlayers ?? {};
  const isMyTurn = ctx.currentPlayer === playerID;
  const myPlayer = G.players[playerID];

  const myCards = myPlayer.hand;
  const myVisibleCards = myPlayer.visibleDeck;
  const myHiddenCards = myPlayer.hiddenDeck;
  const canDiscard =
    activePlayers[playerID] === LuckStage.Play ||
    activePlayers[playerID] === LuckStage.PlayPickup;

  const canSwap = activePlayers[playerID] === LuckStage.Swapping;
  const [selectedCards, setSelectedCard] = useState<LuckCard[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const myCardsRef = useRef<HTMLDivElement>(null);
  const myVisibleCardsRef = useRef<HTMLDivElement>(null);
  const myHiddenCardsRef = useRef<HTMLDivElement>(null);
  const isGameOver = ctx.gameover as GameEndState;
  const winner = isGameOver && isGameOver.winner;

  const extra = myCards.length === 0;

  const cardSelection = (card: LuckCard) => {
    setSelectedCard((previousCards) => {
      if (previousCards) {
        if (
          previousCards
            .map((v) => {
              return v.id;
            })
            .includes(card.id)
        ) {
          return previousCards.filter((val) => {
            return val.id !== card.id;
          });
        } else if (
          previousCards.length > 0 &&
          card.ordinal === previousCards[0].ordinal
        ) {
          return previousCards.concat(card);
        } else {
          return [card];
        }
      } else {
        return [card];
      }
    });
    setSelectedIds((previousIds) => {
      if (previousIds) {
        if (previousIds.includes(card.id)) {
          return previousIds.filter((val) => {
            return val !== card.id;
          });
        } else if (
          previousIds.length > 0 &&
          previousIds[0].split("_")[1] === card.id.split("_")[1]
        ) {
          return previousIds.concat(card.id);
        } else {
          return [card.id];
        }
      } else {
        return [card.id];
      }
    });
  };

  return (
    <div className="absolute top-0 right-0 bottom-0 left-0 bg-green-900 flex flex-col justify-between items-center p-4">
      {winner && (
        <EndGameInfo G={G} didIWin={winner === playerID} winner={winner} />
      )}

      <div id="opponentCards" className="flex flex-row">
        {ctx.playOrder
          .filter((pID) => pID !== playerID)
          .map((pID) => [pID, G.players[pID]] as [string, LuckPlayerState])
          .map(([pID, p]) => {
            return (
              <div
                key={pID}
                className="max-w-sm border-x border-x-neutral-100/25"
              >
                <OpponentHand
                  faceUp={myPlayer.position !== 0}
                  playerID={pID}
                  player={p}
                  highlight={ctx.currentPlayer === pID}
                />
              </div>
            );
          })}
      </div>

      <div
        id="piles"
        className="grid grid-cols-2 gap-6 max-w-lg max-h-40 sm:max-h-60"
      >
        <div
          id="drawPile"
          className="relative max-h-40 sm:max-h-60 flex items-center justify-center "
          onClick={() => {
            moves.drawCardFromDrawPile();
          }}
        >
          <div className="flex flex-col text-center relative max-h-full">
            {G.drawPileLen === 0 ? <CardView none /> : <CardView showBack />}

            <div className="absolute -bottom-6 w-full">
              {G.drawPileLen} cards left
            </div>
          </div>
        </div>

        <div
          id="discardPile"
          className="relative max-h-40 sm:max-h-60 flex items-center justify-center "
          onClick={() => {
            moves.pickUpDiscardPile();
            setSelectedCard(undefined);
            setSelectedIds(undefined);
          }}
        >
          <div className="flex flex-col text-center relative max-h-full">
            {G.discardPile.length === 0 ? (
              <CardView none />
            ) : (
              <CardView card={G.discardPile[G.discardPile.length - 1]} />
            )}
            <div className="absolute -bottom-6 w-full">
              {G.discardPileLen} cards
            </div>
          </div>
        </div>
      </div>

      <div id="myCards" className="flex flex-col items-center">
        <div>Player {Number(playerID) + 1}</div>
        {myPlayer.position ? <div>{myPlayer.position}. Place</div> : <div />}
        <div
          className={`rounded-md max-h-40 mb-2 ${
            isMyTurn && extra ? "bg-yellow-400" : ""
          } `}
        >
          <div
            ref={myVisibleCardsRef}
            className={`relative rounded-md grid grid-cols-3 gap-1 mt-3 -mb-8 max-w-md max-h-20 z-10 ${
              myVisibleCards.length === 0 ? "hidden" : ""
            } `}
          >
            {myVisibleCards.map((c) => (
              <div
                key={c.id}
                className={`relative max-h-20 flex items-center justify-center ${
                  selectedIds?.includes(c.id) ? "-translate-y-4" : ""
                } ${
                  isMyTurn && extra
                    ? "transition ease-in-out hover:-translate-y-5 hover:scale-110"
                    : ""
                }`}
                onClick={() => {
                  if ((isMyTurn && extra) || canSwap) {
                    cardSelection(c);
                  }
                }}
              >
                <div className="flex flex-col absolute -translate-y-full">
                  {selectedCards &&
                    selectedCards[selectedCards.length - 1]?.id === c.id &&
                    extra && (
                      <Button
                        onClick={() => {
                          moves.discardVisibleCard(selectedCards!);
                          setSelectedCard(undefined);
                          setSelectedIds(undefined);
                        }}
                      >
                        Discard
                      </Button>
                    )}
                  {selectedCards &&
                    selectedCards[selectedCards.length - 1]?.id === c.id &&
                    canSwap && (
                      <Button
                        onClick={() => {
                          moves.pickUpCard(selectedCards!);
                          setSelectedCard(undefined);
                          setSelectedIds(undefined);
                        }}
                      >
                        Pick Up
                      </Button>
                    )}
                </div>
                <CardView card={c} />
              </div>
            ))}
          </div>
          <div
            ref={myHiddenCardsRef}
            className={`rounded-md grid grid-cols-3 gap-1 max-w-md max-h-20`}
          >
            {myHiddenCards.map((c) => (
              <div
                className={`relative max-h-20 flex items-center justify-center ${
                  selectedIds?.includes(c.id) ? "-translate-y-4" : ""
                } ${
                  isMyTurn && extra && myVisibleCards.length === 0
                    ? "transition ease-in-out hover:-translate-y-5 hover:scale-110"
                    : ""
                }`}
                key={c.id}
                onClick={() => {
                  if (isMyTurn && extra && myVisibleCards.length === 0) {
                    cardSelection(c);
                  }
                }}
              >
                <div className="flex flex-col absolute -translate-y-full">
                  {selectedCards &&
                    selectedCards[selectedCards.length - 1]?.id === c.id &&
                    extra && (
                      <Button
                        onClick={() => {
                          moves.discardHiddenCard(selectedCards!);                          
                          setSelectedCard(undefined);
                          setSelectedIds(undefined);
                        }}
                      >
                        Discard
                      </Button>
                    )}
                </div>
                <CardView showBack />
              </div>
            ))}
          </div>
        </div>
        {myPlayer.handLength !== 0 ? (
          <div
            id="myHand"
            ref={myCardsRef}
            className={`p-2 rounded-md flex justify-evenly max-h-40 pr-6 md:pr-2 ${
              isMyTurn && !extra ? "bg-yellow-400" : "bg-green-900"
            } `}
          >
            {myCards.map((c) => (
              <div
                className="-mr-4 md:mr-0"
                key={c.id}
                onClick={() => {
                  if (canDiscard || canSwap) {
                    cardSelection(c);
                  }
                }}
              >
                <div
                  className={`relative flex flex-col items-center transition max-h-full transition ease-in-out hover:-translate-y-5 hover:scale-110 z-20 ${
                    selectedIds?.includes(c.id) ? "-translate-y-4" : ""
                  }`}
                >
                  <div className="flex flex-col absolute -translate-y-full">
                    {selectedCards &&
                      selectedCards[selectedCards.length - 1]?.id === c.id &&
                      canDiscard && (
                        <Button
                          onClick={() => {
                            moves.discardCard(selectedCards!);
                            setSelectedCard(undefined);
                            setSelectedIds(undefined);
                          }}
                        >
                          Discard
                        </Button>
                      )}
                    {selectedCards &&
                      selectedCards[selectedCards.length - 1]?.id === c.id &&
                      canSwap && (
                        <Button
                          onClick={() => {
                            moves.placeCard(selectedCards!);
                            setSelectedCard(undefined);
                            setSelectedIds(undefined);
                          }}
                        >
                          Place
                        </Button>
                      )}
                  </div>
                  <CardView card={c} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div />
        )}
        <div className="flex flex-col absolute -translate-y-full">
          {canSwap && (
            <Button
              onClick={() => {
                moves.swapDone();
                setSelectedCard(undefined);
                setSelectedIds(undefined);
              }}
            >
              Done Swapping
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LuckBoard;
