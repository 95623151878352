import { Client } from "boardgame.io/react";
import { SocketIO } from "boardgame.io/multiplayer";

import { Luck } from "./Game";
import LuckBoard from "./Board";
import Lobby from "./Lobby";

const hideLobby = false;

const LuckClient = Client({
  game: Luck,
  board: LuckBoard,
  numPlayers: 4,
  multiplayer: SocketIO({ server: "https://luck.kalmbach.dev" }),
});

const App: React.FC = () => {
  return hideLobby ? (
    <div className="absolute w-full h-full flex gap-3 flex-wrap">
      <div className="relative w-full h-full">
        <LuckClient playerID="0" />
      </div>
      <div className="relative w-full h-full">
        <LuckClient playerID="1" />
      </div>
      <div className="relative w-full h-full">
        <LuckClient playerID="2" />
      </div>
      <div className="relative w-full h-full">
        <LuckClient playerID="3" />
      </div>
    </div>
  ) : (
    <Lobby />
  );
};

export default App;
